import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../layout/Layout'
import Seo from '../components/Seo'
import { Contact } from '../sections/index'

import hero from '../images/banners/allency-banner-contact-us.png'
import SmallHeroBand from '../sections/SmallHeroBand'

const IndexPage = () => {
  const dataImage = useStaticQuery(graphql`
    query {
      rocket: file(relativePath: { eq: "stock/Allency_Banner_rocket.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)

  const rocket = dataImage.rocket.childImageSharp.gatsbyImageData

  const contents = {
    hero: {
      image: hero,
      title: 'Contacto',
    }
  }

  return (
    <Layout lightHeader={true}>
      <Seo
        lang={'es'}
        title={'Comencemos tu proyecto'}
        description={"Estamos listos para que despeguemos tu proyecto juntos y brindarte soluciones integrales de publicidad y marketing para tu negocio."}
        keywords={['Agencia,Marketing,Publicidad,Proyecto,Soluciones,Resultados']}
        noIndex={''}
        noFollow={''}
        slug={'contact'}
        canonicalUrl={'https://allency.com.mx/contact'}
        ogTitle={'Comencemos tu proyecto'}
        ogType={'website'}
        ogDescription={"Estamos listos para que despeguemos tu proyecto juntos y brindarte soluciones integrales de publicidad y marketing para tu negocio."}
        ogImage={'../../images/logos/allency-dark.png'}
      />

      <SmallHeroBand contents={contents.hero} headerSpace={true} />
      <Contact contents={{image: rocket}} />
    </Layout>
  )
}

export default IndexPage